import './bootstrap';

$(document).ready(function () {
    $("#themeButton").click(function () {
        const htmlElement = $("html");
        const currentTheme = htmlElement.attr("data-bs-theme");
        const newTheme = currentTheme === "dark" ? "white" : "dark";
        htmlElement.attr("data-bs-theme", newTheme);
        localStorage.setItem("theme", newTheme);
        console.log("theme", newTheme);
        /* toggle anim */
        const themeButton = $("#themeButton");
        themeButton.removeClass("anim");
        themeButton.removeClass("animation-done");
        setTimeout(function () {
            themeButton.addClass("anim");
        }, 25);
    });

    $("#themeButton").on("animationend", function () {
        $(this).addClass("animation-done");
    });

    (function () {
        "use strict";
        const tooltipTriggerList = Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.forEach(function (tooltipTriggerEl) {
            new Tooltip(tooltipTriggerEl);
        });
    })();

    const allToasts = $(".toast");

    allToasts.each(function () {
        const toastOverlay = $(".toast-overlay");
        const toastInstance = new bootstrap.Toast($(this)[0]);
        toastOverlay.removeClass("hidden");
        toastInstance.show();

        $(this).on("hidden.bs.toast", function () {
            toastOverlay.addClass("hidden");
        });
    });


});

window.showToast = function (message, notificationID, type, delay) {
    showToast(message, notificationID, type, delay)
};
function showToast(message, notificationID, type = 'info', delay = 5000) {
    // Generate a unique ID for the toast
    const uniqueId = 'toast-' + Date.now() + '-' + Math.floor(Math.random() * 1000);

    // Determine the background color based on the type
    let bgColor = '';
    switch (type) {
        case 'success':
            bgColor = 'bg-success';
            break;
        case 'error':
            bgColor = 'bg-danger';
            break;
        case 'info':
        default:
            bgColor = 'bg-info';
            break;
    }

    // Create the toast HTML with the unique ID and dynamic data-bs-delay
    const toastHtml = `
        <div id="${uniqueId}" data-notification="${notificationID}" class="toast show text-white ${bgColor}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="${delay}">
            <div class="d-flex">
                <div class="toast-body">
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `;

    // Append the new toast HTML to the toast container
    const toastContainer = $('.toast-container');
    toastContainer.append(toastHtml);

    // Initialize the new toast and show it
    const toastElement = $('#' + uniqueId);
    const toastInstance = new bootstrap.Toast(toastElement[0]);
    toastInstance.show();

    // Automatically remove the toast after it's hidden
    toastElement.on('hidden.bs.toast', function () {
        //call ajax set to is_read true on notification
        $.ajax({
            url: '/notification/saw',
            type: 'POST',
            data: {
                notification: $(this).data('notification'),
                _token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                console.log(response);
            },
            error: function (error) {
                console.log(error);
            }
        });

        $(this).remove();
    });

    // Set a timeout to hide the toast after the specified delay
    setTimeout(() => {
        toastInstance.hide();
    }, delay);

    // Blink the new toast
    toastElement.addClass('blink');
    setTimeout(() => {
        toastElement.removeClass('blink');
    }, 1000); // Adjust the blink duration as needed
}
