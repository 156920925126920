import axios from "axios";
import Pusher from "pusher-js";
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

Pusher.logToConsole = true;

window.pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  encrypted: true, authEndpoint: "/broadcasting/auth", endpoint: "/broadcasting/auth", forceTLS: true, enableStats: false, auth: {
    params: {
      auth_timeout: 3000,
      // Increase timeout to 60 seconds
    }, headers: { 'X-CSRF-Token': '{{ csrf_token() }}', },
  }, channelAuthorization: { endpoint: "/broadcasting/auth", headers: { 'X-CSRF-Token': '{{ csrf_token() }}', }, },
});