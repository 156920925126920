import './bootstrap';

$(document).ready(function () {
    // Theme toggle functionality
    $("#themeButton").click(function () {
        const htmlElement = $("html");
        const currentTheme = htmlElement.attr("data-bs-theme");
        const newTheme = currentTheme === "dark" ? "white" : "dark";
        htmlElement.attr("data-bs-theme", newTheme);
        localStorage.setItem("theme", newTheme);
        console.log("theme", newTheme);
        /* toggle anim */
        const themeButton = $("#themeButton");
        themeButton.removeClass("anim");
        themeButton.removeClass("animation-done");
        setTimeout(function () {
            themeButton.addClass("anim");
        }, 25);
    });

    $("#themeButton").on("animationend", function () {
        $(this).addClass("animation-done");
    });

    // Enhanced sidebar toggle functionality
    $("#sidebar-toggle").click(function() {
        const $app = $("#app");
        $app.toggleClass("sidebar-collapsed");

        // Toggle sidebar-open class for mobile view
        if (window.innerWidth <= 768) {
            $app.toggleClass("sidebar-open");
        }
    });

    // Close sidebar when clicking outside on mobile
    $(document).on('click touchstart', function(e) {
        const $sidebar = $('#sidebar');
        const $sidebarToggle = $('#sidebar-toggle');

        if (window.innerWidth <= 768) {
            // If we click outside sidebar and not on the toggle button
            if (!$sidebar.is(e.target) && $sidebar.has(e.target).length === 0 &&
                !$sidebarToggle.is(e.target) && $sidebarToggle.has(e.target).length === 0) {
                $('#app').removeClass('sidebar-open').addClass('sidebar-collapsed');
            }
        }
    });

    // Submenu toggle functionality
    $('.sidebar-link[data-bs-toggle="collapse"]').on('click', function() {
        const $parent = $(this).parent('.sidebar-item');

        // Close other open submenus
        $('.sidebar-item').not($parent).find('.collapse.show').collapse('hide');

        // Toggle submenu state classes
        if ($($(this).data('bs-target')).hasClass('show')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });

    // Initialize tooltips
    (function () {
        "use strict";
        const tooltipTriggerList = Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.forEach(function (tooltipTriggerEl) {
            new bootstrap.Tooltip(tooltipTriggerEl);
        });
    })();

    // Initialize tooltips for XML file paths
    const filePathElements = document.querySelectorAll('.text-truncate[title]');
    if (filePathElements.length > 0) {
        filePathElements.forEach(element => {
            new bootstrap.Tooltip(element, {
                placement: 'bottom',
                boundary: 'window'
            });
        });
    }

    // Toast handling
    const allToasts = $(".toast");

    allToasts.each(function () {
        const toastOverlay = $(".toast-overlay");
        const toastInstance = new bootstrap.Toast($(this)[0]);
        toastOverlay.removeClass("hidden");
        toastInstance.show();

        $(this).on("hidden.bs.toast", function () {
            toastOverlay.addClass("hidden");
        });
    });

    // Check if we should set a theme based on local storage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
        $("html").attr("data-bs-theme", savedTheme);
    }
});

window.showToast = function (message, notificationID, type, delay) {
    showToast(message, notificationID, type, delay)
};

function showToast(message, notificationID, type = 'info', delay = 5000) {
    // Generate a unique ID for the toast
    const uniqueId = 'toast-' + Date.now() + '-' + Math.floor(Math.random() * 1000);

    // Determine the background color based on the type
    let bgColor = '';
    switch (type) {
        case 'success':
            bgColor = 'bg-success';
            break;
        case 'error':
            bgColor = 'bg-danger';
            break;
        case 'info':
        default:
            bgColor = 'bg-info';
            break;
    }

    // Create the toast HTML with the unique ID and dynamic data-bs-delay
    const toastHtml = `
        <div id="${uniqueId}" data-notification="${notificationID}" class="toast show text-white ${bgColor}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="${delay}">
            <div class="d-flex">
                <div class="toast-body">
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `;

    // Append the new toast HTML to the toast container
    const toastContainer = $('.toast-container');
    toastContainer.append(toastHtml);

    // Initialize the new toast and show it
    const toastElement = $('#' + uniqueId);
    const toastInstance = new bootstrap.Toast(toastElement[0]);
    toastInstance.show();

    // Automatically remove the toast after it's hidden
    toastElement.on('hidden.bs.toast', function () {
        //call ajax set to is_read true on notification
        $.ajax({
            url: '/notification/saw',
            type: 'POST',
            data: {
                notification: $(this).data('notification'),
                _token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                console.log(response);
            },
            error: function (error) {
                console.log(error);
            }
        });

        $(this).remove();
    });

    // Set a timeout to hide the toast after the specified delay
    setTimeout(() => {
        toastInstance.hide();
    }, delay);

    // Blink the new toast
    toastElement.addClass('blink');
    setTimeout(() => {
        toastElement.removeClass('blink');
    }, 1000); // Adjust the blink duration as needed
}
